import React from "react"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import DefaultSeparator from "./_component_default_separator"

import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import fixtures from "@src/components/HouseAd/fixtures"

const HouseAdPage = () => {
  const title = "The House Ad Page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <h2>No columns</h2>
      {Object.keys(fixtures).map(template => {
        return (
          <section key={template}>
            <h3>{template}</h3>
            <DefaultSeparator />
            <StructuredContentRenderer>
              {fixtures[template]}
            </StructuredContentRenderer>
            <DefaultSeparator />
          </section>
        )
      })}
      <Columns gap="1">
        <Column width={{ desktop: 8, mobile: 12 }}>
          <h2>Multiple columns</h2>
          {Object.keys(fixtures).map(template => {
            return (
              <section key={template}>
                <h3>{template}</h3>
                <DefaultSeparator />
                <StructuredContentRenderer>
                  {fixtures[template]}
                </StructuredContentRenderer>
                <DefaultSeparator />
              </section>
            )
          })}
        </Column>
        <Column width={{ desktop: 4, mobile: 12 }}>
          <h2>Side rail</h2>
          {Object.keys(fixtures).map(template => {
            return (
              <section key={template}>
                <h3>{template}</h3>
                <DefaultSeparator />
                <StructuredContentRenderer>
                  {fixtures[template]}
                </StructuredContentRenderer>
                <DefaultSeparator />
              </section>
            )
          })}
        </Column>
      </Columns>
    </Page>
  )
}

export default HouseAdPage
