const buildHouseAdProps = template => ({
  __typename: "HouseAd",
  id: "1078075",
  body: "Find the right payment provider to meet your unique business needs.",
  ctaAuthLink: null,
  ctaText: "SEE YOUR OPTIONS",
  ctaType: "WEB_LINK",
  ctaUrl:
    "https://nerdwallet.fundera.com/apply-for-a-loan?application_flow=payments&aid=nerdwallet",
  headline: "Make payments make sense",
  template,
  featuredMedia: {
    __typename: "MediaItem",
    altText: null,
    croppedImages: [
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-105x63.png",
        width: 105,
        height: 63,
        size: "PRIMARY_XXS",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-105x82.png",
        width: 105,
        height: 82,
        size: "PRIMARY_XXS_UNCROPPED",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-320x192.png",
        width: 320,
        height: 192,
        size: "PRIMARY_XS",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-320x251.png",
        width: 320,
        height: 251,
        size: "PRIMARY_XS_UNCROPPED",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-480x288.png",
        width: 480,
        height: 288,
        size: "PRIMARY_S",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-480x376.png",
        width: 480,
        height: 376,
        size: "PRIMARY_S_UNCROPPED",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-600x360.png",
        width: 600,
        height: 360,
        size: "PRIMARY_M",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-600x470.png",
        width: 600,
        height: 470,
        size: "PRIMARY_M_UNCROPPED",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-770x462.png",
        width: 770,
        height: 462,
        size: "PRIMARY_L",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-770x603.png",
        width: 770,
        height: 603,
        size: "PRIMARY_L_UNCROPPED",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-70x70.png",
        width: 70,
        height: 70,
        size: "SQUARE",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-631x250.png",
        width: 631,
        height: 250,
        size: "BB_ARTICLE",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-160x160.png",
        width: 160,
        height: 160,
        size: "SQUARE_160",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-350x350.png",
        width: 350,
        height: 350,
        size: "SQUARE_350",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final.png",
        width: 960,
        height: 752,
        size: "FULL",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-750x588.png",
        width: 750,
        height: 588,
        size: "MEDIUM",
      },
      {
        __typename: "CroppedImageSize",
        sourceUrl:
          "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2021/10/21Q4-payments-badge-final-768x602.png",
        width: 768,
        height: 602,
        size: "MEDIUM_LARGE",
      },
    ],
  },
  ctaHref:
    "https://nerdwallet.fundera.com/apply-for-a-loan?application_flow=payments&aid=nerdwallet",
})

const vertical = {
  type: "WPHouseAd",
  version: "1.0.0",
  props: {
    id: 1078075,
    children: null,
    houseAd: buildHouseAdProps("vertical"),
  },
}

const horizontal = {
  type: "WPHouseAd",
  version: "1.0.0",
  props: {
    id: 1078075,
    children: null,
    houseAd: buildHouseAdProps("horizontal"),
  },
}

const entry_banner = {
  type: "WPHouseAd",
  version: "1.0.0",
  props: {
    id: 1078075,
    children: null,
    houseAd: buildHouseAdProps("entry_banner"),
  },
}

const card = {
  type: "WPHouseAd",
  version: "1.0.0",
  props: {
    id: 1078075,
    children: null,
    houseAd: buildHouseAdProps("card"),
  },
}

const fixtures = {
  vertical,
  horizontal,
  entry_banner,
  card,
}

export default fixtures
